@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/react-tabs/style/react-tabs.scss';

.App-header {
    font-size: calc(10px + 2vmin);
}
.App {
    left: 10px;
    position: absolute;
    width: 100%;
}
.container {
    max-width: 100% !important;
    .customCarousel{
      display: grid;
    }
}
.search {
    float: right;
}
.teamMember {
    img {
        width: 443px;
        height: 431px;
        object-fit: cover;
        object-position: 50% 50%;
    }
    span {
        display: flex;
        font-size: 20px;
        > p {
            margin-right: 10px;
        }
        > a {
            margin-right: 10px;
        }
    }
}
.home {
}
